import apiFetch from '@wordpress/api-fetch'
import anime from 'animejs'
import splt from 'spltjs'
import UtilsComponent from '../components/utils.component'

export default class HomeService {
    isMobile = window.matchMedia('(max-width: 1025px)').matches
    isEcomode = IRISCollectionCustomer.isEcomodeActive
    speed = 1000
    swipers = []

    constructor() {
        this.incontournables()
        this.exploration()
        this.decouvertes()
        this.agenda()
        this.social()
        this.pratique()
        this.animations()
    }

    async incontournables() {
        const point = '.section-incontournables__map__point'

        if (this.isMobile) {
            // move map inside slides on mobiles
            $('.section-incontournables__map').appendTo('.customer-post__heading')
        }

        this.swipers.incontournables = await UtilsComponent.customerSlider(
            'incontournables',
            {
                effect: 'fade',
                slidesPerView: 1,
                spaceBetween: 0,
                fadeEffect: {
                    crossFade: true,
                },
            },
            this.isMobile
                ? '.section-incontournables__content > .customer__links'
                : '.section-incontournables .swiper-slide .customer-post__footer',
            !this.isMobile ? '.section-incontournables .customer-slider__navigation' : null,
        )

        this.swipers.incontournablesbg = await UtilsComponent.customerSlider(
            'incontournables-background',
            {
                effect: 'fade',
                slidesPerView: 1,
                spaceBetween: 0,
                fadeEffect: {
                    crossFade: true,
                },
            },
        )

        // Link instances
        this.swipers.incontournables.controller.control = this.swipers.incontournablesbg
        this.swipers.incontournablesbg.controller.control = this.swipers.incontournables

        // Active points on slide
        this.swipers.incontournables.on('slideChange', (e) => {
            const index = this.swipers.incontournables.activeIndex + 1
            $(point).removeClass('is-active')
            $(`[data-customer-map-point="${index}"]`).addClass('is-active')
        })

        // Slide on points click
        $(document).on('click', point, (e) => {
            const index = $(e.currentTarget).index()
            $(point).removeClass('is-active')
            $(`[data-customer-map-point="${index}"]`).addClass('is-active')
            this.swipers.incontournables.slideTo(index)
        })
    }

    async exploration() {
        $('.section-exploration .section__wrapper > .customer__links').appendTo(
            '.section-exploration .customer-card__content',
        )

        if (!this.isEcomode) {
            this.swipers.exploration = await UtilsComponent.customerSlider(
                'exploration',
                {
                    speed: this.speed,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    effect: this.isMobile ? 'slide' : 'coverflow',
                    coverflowEffect: {
                        depth: 400,
                        rotate: 0,
                        slideShadows: false,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1.15,
                            spaceBetween: 15,
                        },
                        1001: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                    },
                },
                !this.isMobile ? '.section-exploration .customer__links:last-child' : null,
            )
        }
    }

    async decouvertes() {
        !this.isMobile
            ? $('.section-decouvertes .customer__links').appendTo(
                  '.section-decouvertes .section__heading',
              )
            : null

        if (!this.isEcomode) {
            this.swipers.decouvertes = await UtilsComponent.customerSlider(
                'decouvertes',
                {
                    slidesPerView: 2,
                    spaceBetween: 40,
                    breakpoints: {
                        0: {
                            slidesPerView: 1.15,
                            spaceBetween: 15,
                        },
                        650: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1001: {
                            slidesPerView: 1.15,
                            spaceBetween: 40,
                        },
                        1201: {
                            slidesPerView: 1.85,
                            spaceBetween: 40,
                        },
                    },
                },
                !this.isMobile ? '.section-decouvertes .section__heading' : null,
            )
        }
    }

    async agenda() {
        if (!this.isEcomode) {
            this.swipers.agenda = await UtilsComponent.customerSlider(
                'agenda',
                {
                    slidesPerView: 4,
                    breakpoints: {
                        0: {
                            slidesPerView: 1.25,
                            spaceBetween: 10,
                        },
                        650: {
                            slidesPerView: 2.25,
                            spaceBetween: 20,
                        },
                        1000: {
                            slidesPerView: 3.25,
                            spaceBetween: 40,
                        },
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                    },
                },
                !this.isMobile ? '.section-agenda .section__footer' : null,
            )
        }
    }

    pratique() {
        $(document).on('mouseover', '.section-pratique .customer-card', (e) => {
            $(e.currentTarget).addClass('is-active') // Add your class to siblings
            $(e.currentTarget).siblings().addClass('is-inactive') // Add your class to siblings
        })

        $(document).on('mouseout', '.section-pratique .customer-card', (e) => {
            $(e.currentTarget).removeClass('is-active') // Remove your class from siblings on mouse out
            $(e.currentTarget).siblings().removeClass('is-inactive') // Remove your class from siblings on mouse out
        })
    }

    async social() {
        if (window.matchMedia('(min-width: 1001px)').matches && !this.isEcomode) {
            const wrapper = '[data-id="strate-social"]'
            const container = '[data-id="social-wall"]'
            if ($(container).length > 0 && !$(container).is(':hidden')) {
                $(container).html('<p class="text-center">Articles en cours de chargement...</p>')
                apiFetch({
                    path: IRISCollectionCustomer.irisSsoInstagramUrl,
                    method: 'POST',
                    data: {
                        limit: 5,
                    },
                })
                    .then((result) => {
                        $(container).html(result.render)
                        $(wrapper).addClass('is-loaded')
                        $('body').addClass('has-social')
                        import(
                            '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component' /* webpackChunkName: "scripts/hc_lazyload" */
                        ).then(({ HcLazyload }) => {
                            HcLazyload.update()
                        })
                    })
                    .catch(() => {
                        $(container).html('')
                    })
            }
        }
    }

    animations() {
        splt({ target: '.header__heading__title' })
        $('.header__heading__title').addClass('is-splitted')

        setTimeout(
            () => {
                anime({
                    targets: '.header__heading__title .char',
                    translateY: [40, 0],
                    opacity: [0, 1],
                    delay: anime.stagger(50),
                })

                $('.customer-loader').removeClass('is-loading')
            },
            Math.floor(Math.random() * (1500 - 900 + 1)) + 900,
        )

        UtilsComponent.initCustomerScrollReveal()

        if (!this.isMobile && !this.isEcomode) {
            $(window).scroll(function () {
                const scrollValue = $(this).scrollTop() / 1.5
                const opacityValue = 1 - scrollValue / (window.innerHeight / 2) // Adjust the division value as needed

                if (scrollValue < $(window).height()) {
                    $('.header').css({
                        transform: `translateY(${scrollValue}px)`,
                        opacity: opacityValue,
                    })
                }
            })

            //Init parallax animations
            $('.section-inspiration img').attr('data-customer-parallax', '')
            UtilsComponent.initCustomerParallax()
        }
    }
}
